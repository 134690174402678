.SignUpIn {
  text-align: center;
}

form.form {
  width: 50vmin;
}

.form-control{text-align:center;}

.Login-logo {
  height: 40vmin;
  pointer-events: none;
}

.ForgotSignUp-logo {
  height: 20vmin;
  width: 60vmin;
  pointer-events: none;
}

.forgot-title {
  color: #cccccc;
}

.signUp-title {
  color:#282c34;
}

.birthday-group select.form-control {
  display: inline-flex;
  max-width: 30%;
  margin-right: 1%;
  margin-left: 1%;
}

.SignUpIn-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #cccccc;
}

.SignUp-header {
  background-color: #282c34;
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.SignUp-logo {
  max-height: 20v;
  width: 60vmin;
  pointer-events: none;
}

.SignUp-container {
    background-color: #ffffff;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.SignUpIn-link {
  color: #61dafb;
}

.SignUpIn-submit {
    background-color: #662D91 !important;
    color: #ffffff !important;
    font-weight: bold !important;
    border-color: #9165B7 !important;
}

.to-location-text .text-link {
  font-size: calc(10px + 1vmin);
  color: #cccccc !important;
}

.checkbox-text {
  font-size: 16px;
  color: #cccccc;
}

label.check-side {
  display: inline-flex;
  max-width: 48%;
  padding: 0 6%;
}

.error-message {
  font-size: 14px;
  color: red;
  padding: 0 0 10px 0;
}

span.text-link {
  cursor: default;
}

#forgot_login:hover, #sign_up:hover, #login:hover {
  color: #9165B7 !important;
  text-decoration: none !important;
  cursor: pointer;
}

#slogan {
  text-align: center;
  font-size: calc(10px + 3vmin);
  font-family: cursive;
}
